"use client";

import { Button } from "@syde-ui";
import NextError from "next/error";
import { useEffect } from "react";

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
  }, [error]);

  return (
    <html>
      <body>
        <NextError statusCode={undefined as any} />
        <Button onClick={() => reset()}>Try again</Button>
      </body>
    </html>
  );
}
